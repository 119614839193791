<template>
    <b-dropdown class="d-inline-block language-switch" right variant="white">
        <template v-slot:button-content>
            <div v-b-tooltip.hover.top="$t('general.lang_selector')" class="d-flex align-items-center">
                <img :src="flag" alt="Header Language" class="me-2" height="16"/>
                <template v-if="!iconBtn">{{ text }}</template>
            </div>
        </template>
        <b-dropdown-item
            v-for="(entry, i) in languages"
            :key="`Lang${i}`"
            :class="{ active: lan === entry.language }"
            :value="entry"
            class="notify-item"
            @click="setLanguage(entry.language, entry.title, entry.flag)"
        >
            <img
                :src="`${entry.flag}`"
                alt="user-image"
                class="me-1"
                height="12"
            />
            <span class="align-middle">{{ entry.title }}</span>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
import i18n from "../i18n";
// import moment from "moment/moment";
import {mapGetters} from "vuex";

export default {
    name: "language-selector",

    props: {
        iconBtn: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            languages: 'locale/languages',
            lan: 'locale/lang',
            text: 'locale/text',
            flag: 'locale/flag',
            value: 'locale/value'
        })
    },

    /*data() {
        return {
            languages: [
                {
                    flag: require("@/assets/images/flags/us.jpg"),
                    language: "en",
                    title: "English",
                },
                {
                    flag: require("@/assets/images/flags/hungary.jpg"),
                    language: "hu",
                    title: "Magyar",
                },
            ],
            lan: i18n.locale,
            text: null,
            flag: null,
            value: null,
        }
    },*/

    methods: {
        setLanguage(locale) {
            this.$store.dispatch('locale/modify', locale);
            /*this.lan = locale;
            this.text = country;
            this.flag = flag;
            i18n.locale = locale;
            moment.locale(locale);
            window.localStorage.setItem('locale', locale);
            window.sessionStorage.setItem('locale', locale);
            this.$store.dispatch('user/saveLocale', locale);*/
        },
    },

    mounted() {
        this.$store.dispatch('locale/set', i18n.locale);
    },
}
</script>

<style scoped>

</style>
