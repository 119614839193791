<script>

import {authMethods, authFackMethods} from "@/state/helpers";

import i18n from "../i18n";
import moment from "moment";
import {mapGetters} from "vuex";
import LanguageSelector from "./language-selector.vue";

/**
 * Topbar component
 */
export default {
    computed: {
        ...mapGetters({
            user: 'auth/currentUser',
            balance: 'credit/balance',
            notifications: 'notification/list',
            notificationsAlert: 'notification/alert',
            newNotifications: 'notification/news',
            readNotifications: 'notification/read',
            noMoreNotifications: 'notification/noMore',
            notificationLoading: 'notification/loading'
        }),

        balanceLink: function () {
            return this.$t('routes.balance');
        },

        profileLink: function () {
            return this.$t('routes.profile');
        },

        messageLink: function () {
            return this.$t('routes.messages').substr(0, this.$t('routes.messages').indexOf(':'));
        },

        invoiceLink: function () {
            return this.$t('routes.invoices');
        },
    },
    data() {
        return {
            moment: moment,
            languages: [
                {
                    flag: require("@/assets/images/flags/us.jpg"),
                    language: "en",
                    title: "English",
                },
                {
                    flag: require("@/assets/images/flags/hungary.jpg"),
                    language: "hu",
                    title: "Magyar",
                },
                /*{
                  flag: require("@/assets/images/flags/spain.jpg"),
                  language: "es",
                  title: "Spanish",
                },
                {
                  flag: require("@/assets/images/flags/germany.jpg"),
                  language: "de",
                  title: "German",
                },
                {
                  flag: require("@/assets/images/flags/italy.jpg"),
                  language: "it",
                  title: "Italian",
                },
                {
                  flag: require("@/assets/images/flags/russia.jpg"),
                  language: "ru",
                  title: "Russian",
                },*/
            ],
            lan: i18n.locale,
            text: null,
            flag: null,
            value: null,
        }
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        layoutscroll: {
            type: Boolean,
            required: true,
        },
    },
    mounted() {
        this.value = this.languages.find((x) => x.language === i18n.locale);
        this.text = this.value.title;
        this.flag = this.value.flag;
        if (!this.balance) {
            this.fetchBalance();
        }
        this.$store.dispatch('notification/fetchList');
    },
    methods: {
        authLogout: authMethods.logOut,
        authFakeLogout: authFackMethods.logout,
        toggleRightSidebar() {
            this.$parent.toggleRightSidebar();
        },
        fetchBalance: function () {
            this.$store.dispatch('credit/fetchBalance');
        },
        logout() {
            //if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
            this.authLogout().then(() => {
                this.$router.push(
                    this.$route.query.redirectFrom || {
                        name: "login",
                    }
                );
            });
            /*} else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
                this.authFakeLogout().then(() => {
                    this.$router.push(
                        this.$route.query.redirectFrom || {
                            name: "login",
                        }
                    );
                });
            }*/
        },
        toggleMenu() {
            this.$parent.toggleMenu();
        },
        setLanguage(locale, country, flag) {
            this.lan = locale;
            this.text = country;
            this.flag = flag;
            i18n.locale = locale;
            moment.locale(locale);
            window.localStorage.setItem('locale', locale);
            window.sessionStorage.setItem('locale', locale);
            this.$store.dispatch('user/saveLocale', locale);
        },
        loadMoreNotification: function ($event) {
            if (!this.noMoreNotifications && $event.target.scrollHeight === $event.target.scrollTop + $event.target.offsetHeight) {
                this.$store.dispatch('notification/more');
            }
        },
    },
    components: {
        LanguageSelector,
    },
    watch: {
        type: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case "dark":
                            document.body.setAttribute("data-topbar", "dark");
                            break;
                        case "light":
                            document.body.setAttribute("data-topbar", "light");
                            document.body.removeAttribute("data-layout-size", "boxed");
                            break;
                        default:
                            document.body.setAttribute("data-topbar", "dark");
                            break;
                    }
                }
            },
        },
        layoutscroll: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case false:
                            document.body.setAttribute("data-layout-scrollable", "false");
                            break;
                        case true:
                            document.body.setAttribute("data-layout-scrollable", "true");
                            break;
                        default:
                            document.body.setAttribute("data-layout-scrollable", "false");
                            break;
                    }
                }
            },
        },
    },
};
</script>

<template>
    <div class="navbar-header">
        <div class="d-flex">
            <!-- LOGO -->
            <div class="navbar-brand-box">
                <router-link class="logo logo-dark" :to="{path: $t('routes.profile')}">
                    <span class="logo-sm">
                        <span class="logo-text">SimpleSMS</span>
<!--                        <img alt="" height="22" src="@/assets/images/logo-sm.png"/>-->
                    </span>
                    <span class="logo-lg">
                        <span class="logo-text">SimpleSMS</span>
<!--                        <img alt="" height="22" src="@/assets/images/logo-dark.png"/>-->
                    </span>
                </router-link>

                <router-link class="logo logo-light" :to="{path: $t('routes.profile')}">
                    <span class="logo-sm">
                        <img alt="" height="22" src="@/assets/images/logo-sm.png"/>
                    </span>
                    <span class="logo-lg">
                        <img alt="" height="22" src="@/assets/images/logo-light.png"/>
                    </span>
                </router-link>
            </div>

            <button
                class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
                type="button"
                @click="toggleMenu"
            >
                <i class="fa fa-fw fa-bars"></i>
            </button>

        </div>

        <div class="d-flex">
            <language-selector class="d-flex align-items-center"></language-selector>

            <b-dropdown
                menu-class="dropdown-menu-lg dropdown-menu-end p-0"
                right
                toggle-class="header-item noti-icon"
                variant="white"
                :disabled="notificationLoading"
            >
                <template v-slot:button-content>
                    <feather v-b-tooltip.hover.top="$t('header.notifications.title')" class="icon-sm" type="bell"></feather>
                    <span v-if="notificationsAlert" class="noti-dot bg-danger"></span>
                </template>
                <div class="p-3">
                    <div class="row align-items-center">
                        <div class="col">
                            <h5 class="m-0 font-size-15">
                                {{ $t("header.notifications.title") }}
                            </h5>
                        </div>
                        <div class="col-auto">
                            <a class="small" href="#!">
                                {{ $t("header.notifications.view_all") }}</a
                            >
                        </div>
                    </div>
                </div>
                <div id="notification-simplebar" :key="'notifications_' + notifications.length"
                     style="max-height: 250px; overflow-y: scroll;" @scroll="loadMoreNotification">
                    <h6 v-if="newNotifications.length" class="dropdown-header bg-light">
                        {{ $t("header.notifications.new") }}
                    </h6>
                    <template v-for="(notification, index) in newNotifications">
                        <b-link :key="'new_' + index" class="text-reset notification-item" :to="$t('routes.messages').substr(0, $t('routes.messages').indexOf(':')) + notification.m_id">
                            <div class="d-flex border-bottom align-items-start">
                                <div class="flex-grow-1">
                                    <h6 class="mb-1">
                                        {{ notification.sender }}
                                    </h6>
                                    <div class="text-muted">
                                        <p class="mb-1 font-size-13">
                                            {{ notification.subject }}
                                        </p>
                                        <p class="mb-0 font-size-10 text-uppercase fw-bold">
                                            <i class="mdi mdi-clock-outline"></i>
                                            {{ moment(notification.create_date, "YYYY-MM-DD HH:mm:ss").fromNow() }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </b-link>
                    </template>
                    <h6 v-if="readNotifications.length" class="dropdown-header bg-light">
                        {{ $t("header.notifications.earlier") }}
                    </h6>
                    <template v-for="(notification, index) in readNotifications">
                        <a :key="'read_' + index" class="text-reset notification-item" href="javascript:void(0);">
                            <div class="d-flex border-bottom align-items-start">
                                <div class="flex-grow-1">
                                    <h6 class="mb-1">
                                        {{ notification.sender }}
                                    </h6>
                                    <div class="text-muted">
                                        <p class="mb-1 font-size-13">
                                            {{ notification.subject }}
                                        </p>
                                        <p class="mb-0 font-size-10 text-uppercase fw-bold">
                                            <i class="mdi mdi-clock-outline"></i>
                                            {{ moment(notification.create_date, "YYYY-MM-DD HH:mm:ss").fromNow() }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </template>
                </div>
                <div class="p-2 border-top">
                    <router-link :to="{ path: '/uzenetek' }" class="btn btn-sm btn-link font-size-14 btn-block text-center">
                        <i class="uil-arrow-circle-right me-1"></i>
                        <span>{{ $t("header.notifications.load_more") }}</span>
                    </router-link>
                </div>
            </b-dropdown>

            <b-dropdown
                class="dropdown d-inline-block"
                menu-class="dropdown-menu-end pt-0"
                right
                toggle-class="header-item user text-start d-flex align-items-center"
                variant="white"
            >
                <template v-slot:button-content>
                    <!--                    <img
                                            alt="Header Avatar"
                                            class="rounded-circle header-profile-user"
                                            src="@/assets/images/users/avatar-1.jpg"
                                        />-->
                    <span class="ms-2 d-block user-item-desc">
                    <span class="user-name">{{ user.realname }}</span>
                    <span class="user-sub-title">{{ user.company_name }}</span>
                  </span>
                </template>

                <div class="p-3 bg-primary border-bottom">
                    <h6 class="mb-0 text-white">{{ user.realname }}</h6>
                    <p class="mb-0 font-size-11 text-white-50 fw-semibold">
                        {{ user.email }}
                    </p>
                </div>
                <router-link :to="profileLink" class="dropdown-item"
                ><i
                    class="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"
                ></i>
                    <span class="align-middle">{{ $t('header.profilewidget.profile') }}</span></router-link
                >
                <router-link :to="messageLink" class="dropdown-item"
                ><i
                    class="mdi mdi-message-text-outline text-muted font-size-16 align-middle me-1"
                ></i>
                    <span class="align-middle">{{ $t('header.profilewidget.message') }}</span></router-link
                >
                <router-link class="dropdown-item" :to="invoiceLink"
                ><i
                    class="mdi mdi-cash text-muted font-size-16 align-middle me-1"
                ></i>
                    <span class="align-middle">{{ $t('header.profilewidget.invoices') }}</span></router-link
                >
                <router-link v-if="false" class="dropdown-item" to="/kanban-board"
                ><i
                    class="mdi mdi-calendar-check-outline text-muted font-size-16 align-middle me-1"
                ></i>
                    <span class="align-middle">{{ $t('header.profilewidget.taskboard') }}</span></router-link
                >
                <router-link v-if="false" class="dropdown-item" to="/pages/faqs"
                ><i
                    class="mdi mdi-lifebuoy text-muted font-size-16 align-middle me-1"
                ></i>
                    <span class="align-middle">{{ $t('header.profilewidget.help') }}</span></router-link
                >
                <b-dropdown-divider></b-dropdown-divider>
                <router-link :key="$i18n.locale" :to="balanceLink" class="dropdown-item"
                ><i
                    class="mdi mdi-wallet text-muted font-size-16 align-middle me-1"
                ></i>
                    <span class="align-middle"
                    >{{ $t('header.profilewidget.balance') }} : <b>{{ $currencyFormatter.format(balance) }}</b></span
                    ></router-link
                >
                <router-link
                    v-if="false"
                    class="dropdown-item d-flex align-items-center"
                    to="/user/settings"
                ><i
                    class="mdi mdi-cog-outline text-muted font-size-16 align-middle me-1"
                ></i>
                    <span class="align-middle">{{ $t('header.profilewidget.settings') }}</span
                    ><span class="badge badge-soft-success ms-auto"
                    >New</span
                    ></router-link
                >
                <router-link v-if="false" class="dropdown-item" to="/auth/lockscreen-basic"
                ><i
                    class="mdi mdi-lock text-muted font-size-16 align-middle me-1"
                ></i>
                    <span class="align-middle">{{ $t('header.profilewidget.lockscreen') }}</span></router-link
                >
                <a class="dropdown-item" href="javascript:void(0);" @click="logout()"
                ><i
                    class="mdi mdi-logout text-muted font-size-16 align-middle me-1"
                ></i>
                    <span class="align-middle">{{ $t('header.profilewidget.logout') }}</span></a
                >
            </b-dropdown>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    h6 {
        font-size: 0.875rem;
    }
    #notification-simplebar {
        * {
            line-height: normal;
        }
    }
</style>
<style lang="scss">
#notification-simplebar::-webkit-scrollbar {
    width: 6px;
}

/* Track */
#notification-simplebar::-webkit-scrollbar-track {
    background: white;
    right: 2px;
}

#notification-simplebar::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #a2adb7;
}

/* Handle on hover */
#notification-simplebar::-webkit-scrollbar-thumb:hover {
    background: #a2adb7;
}
</style>
